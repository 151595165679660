const email = [
    "mailto:",
    "webmaster",
    "@",
    "micetf.fr",
    "?",
    "subject=",
    "Au sujet de micetf.fr",
].join("");

export const brand = {
    className: "navbar-brand",
    href: "#",
    link: "MiCetF",
};

export const menu = [
    {
        className: "btn btn-outline-secondary my-1 mx-1",
        href: "https://micetf.fr/blog/",
        target: "_blank",
        title: "Accéder au blog",
        link: "Blog",
    },
];

export const fm = {
    href: email,
    title: "Pour contacter le webmaster...",
};
