// const fs = require("fs");

// const files = fs.readdirSync(".").filter(f => f.match(/[png|jpg|jpeg|gif]$/));
// const imports = files.map(img => {
//     const name = img.replace(/(\..*)/, "");
//     const file = name.replace(/-/g, "_");
//     return `import _${file} from './${img}'`;
// }).join(`
// `);

// const exp = files.map(img => {
//     const name = img.replace(/(\..*)/, "");
//     const file = name.replace(/-/g, "_");
//     const NAME = name.toUpperCase().replace(/-/g, "_");
//     return `export const _${NAME} = _${file}`;
// }).join(`
// `);
// console.log(imports);
// console.log(exp);

import _2a7 from "./2a7.gif";
import _LesPareils from "./LesPareils.jpg";
import _OutilsTICE from "./LesOutilsTICE.png";
import _apinc from "./apinc.png";
import _astro52 from "./astro52.jpg";
import _cartable from "./cartable.jpg";
import _cartables from "./cartables.jpg";
import _chezmarco from "./chezmarco.jpg";
import _classe_tice from "./classe-tice.png";
import _clicouweb from "./clicouweb.gif";
import _ideesash from "./ideesash.png";
import _instit90 from "./instit90.png";
import _lakanal from "./lakanal.jpg";
import _lasourisweb from "./lasourisweb.jpg";
import _logicieleducatif from "./logicieleducatif.jpg";
import _maclasseweb from "./maclasseweb.jpg";
import _mathannuaire from "./mathannuaire.png";
import _monecole from "./monecole.png";
import _moustache from "./moustache.jpg";
import _planete from "./planete.gif";
import _potati from "./potati.png";
import _scalpa from "./scalpa.png";
import _sitespe from "./sitespe.jpg";
import _sitinstit from "./sitinstit.jpg";
import _soutien from "./soutien67.gif";
import _stepfan from "./stepfan.jpg";
import _vivacours from "./vivacours.png";
import _weblitoo from "./weblitoo.gif";
import _webrankinfo_80_15 from "./webrankinfo-80-15.png";
export const _2A7 = _2a7;
export const _LESPAREILS = _LesPareils;
export const _OUTILSTICE = _OutilsTICE;
export const _APINC = _apinc;
export const _ASTRO52 = _astro52;
export const _CARTABLE = _cartable;
export const _CARTABLES = _cartables;
export const _CHEZMARCO = _chezmarco;
export const _CLASSE_TICE = _classe_tice;
export const _CLICOUWEB = _clicouweb;
export const _IDEESASH = _ideesash;
export const _INSTIT90 = _instit90;
export const _LAKANAL = _lakanal;
export const _LASOURISWEB = _lasourisweb;
export const _LOGICIELEDUCATIF = _logicieleducatif;
export const _MACLASSEWEB = _maclasseweb;
export const _MATHANNUAIRE = _mathannuaire;
export const _MONECOLE = _monecole;
export const _MOUSTACHE = _moustache;
export const _PLANETE = _planete;
export const _POTATI = _potati;
export const _SCALPA = _scalpa;
export const _SITESPE = _sitespe;
export const _SITINSTIT = _sitinstit;
export const _SOUTIEN = _soutien;
export const _STEPFAN = _stepfan;
export const _VIVACOURS = _vivacours;
export const _WEBLITOO = _weblitoo;
export const _WEBRANKINFO_80_15 = _webrankinfo_80_15;
