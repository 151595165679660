import _4pions from "./thumbnails/4pions.png";
import _PortraitChinois from "./thumbnails/PortraitChinois.png";
import _Problemes from "./thumbnails/Problemes.png";
import _ProdEcrits from "./thumbnails/ProdEcrits.png";
import _abaque from "./thumbnails/abaque.png";
import _abaques_taquins from "./thumbnails/abaques-taquins.png";
import _acrostiche from "./thumbnails/acrostiche.png";
import _addigrille from "./thumbnails/addigrille.png";
import _additions from "./thumbnails/additions.png";
import _agamographe from "./thumbnails/agamographe.png";
import _alea_cartes from "./thumbnails/alea-cartes.png";
import _alphabet from "./thumbnails/alphabet.png";
import _anagrammes from "./thumbnails/anagrammes.png";
import _angles from "./thumbnails/angles.png";
import _animations from "./thumbnails/animations.png";
import _arborescence from "./thumbnails/arborescence.png";
import _ardoise from "./thumbnails/ardoise.png";
import _audio_tag from "./thumbnails/audio-tag.png";
import _awarhol from "./thumbnails/awarhol.png";
import _barres_doigts from "./thumbnails/barres-doigts.png";
import _bataille from "./thumbnails/bataille.png";
import _blog from "./thumbnails/blog.png";
import _boulier from "./thumbnails/boulier.png";
import _boulierHTML5 from "./thumbnails/boulierHTML5.png";
import _calculmental from "./thumbnails/calculmental.png";
import _cambridge from "./thumbnails/cambridge.png";
import _cartapoints from "./thumbnails/cartapoints.png";
import _cases4bb from "./thumbnails/cases4bb.png";
import _chateau from "./thumbnails/chateau.png";
import _chemins_de_phrases from "./thumbnails/chemins-de-phrases.png";
import _choixpeau from "./thumbnails/choixpeau.png";
import _cibles from "./thumbnails/cibles.png";
import _classement from "./thumbnails/classement.png";
import _clavier from "./thumbnails/clavier.png";
import _cologrilles from "./thumbnails/cologrilles.png";
import _cologrillesgenerateur from "./thumbnails/cologrillesgenerateur.png";
import _complements from "./thumbnails/complements.png";
import _compter from "./thumbnails/compter.png";
import _compteur from "./thumbnails/compteur.png";
import _concatenator from "./thumbnails/concatenator.png";
import _conjugaison from "./thumbnails/conjugaison.png";
import _conversion from "./thumbnails/conversion.png";
import _conversion2 from "./thumbnails/conversion2.png";
import _conversions from "./thumbnails/conversions.png";
import _courseau21 from "./thumbnails/courseau21.png";
import _decimaux from "./thumbnails/decimaux.png";
import _denombrement from "./thumbnails/denombrement.png";
import _dictee from "./thumbnails/dictee.png";
import _dicteenombres from "./thumbnails/dicteenombres.png";
import _didapages from "./thumbnails/didapages.png";
import _differences from "./thumbnails/differences.png";
import _discrimination from "./thumbnails/discrimination.png";
import _divisator from "./thumbnails/divisator.png";
import _dominos from "./thumbnails/dominos.png";
import _eacrostiches from "./thumbnails/eacrostiches.png";
import _ecoute_et_range from "./thumbnails/ecoute-et-range.png";
import _ecrans_ipad from "./thumbnails/ecrans-ipad.png";
import _edidafusion from "./thumbnails/edidafusion.png";
import _embouteillages from "./thumbnails/embouteillages.png";
import _estimer_fraction from "./thumbnails/estimer-fraction.png";
import _estimheure from "./thumbnails/estimheure.png";
import _etiquettes_virtuelles from "./thumbnails/etiquettes-virtuelles.png";
import _etiquettes from "./thumbnails/etiquettes.png";
import _fabricamo from "./thumbnails/fabricamo.png";
import _fabriquehistoires from "./thumbnails/fabriquehistoires.png";
import _flipflap from "./thumbnails/flipflap.png";
import _fluence from "./thumbnails/fluence.png";
import _fractions_generateur from "./thumbnails/fractions-generateur.png";
import _fractions from "./thumbnails/fractions.png";
import _frise_circulaire from "./thumbnails/frise-circulaire.png";
import _frise from "./thumbnails/frise.png";
import _fusionLignes from "./thumbnails/fusionLignes.png";
import _fusionddp from "./thumbnails/fusionddp.png";
import _gabuzomeu from "./thumbnails/gabuzomeu.png";
import _graduations from "./thumbnails/graduations.png";
import _grid4bot from "./thumbnails/grid4bot.png";
import _groupements from "./thumbnails/groupements.png";
import _hashtagfrom from "./thumbnails/hashtagfrom.png";
import _homophones from "./thumbnails/homophones.png";
import _images_sequentielles from "./thumbnails/images-sequentielles.png";
import _imagesetsons from "./thumbnails/imagesetsons.png";
import _jeux_lecture from "./thumbnails/jeux-lecture.png";
import _jeux_mathematiques_1 from "./thumbnails/jeux-mathematiques-1.png";
import _jeux_mathematiques_2 from "./thumbnails/jeux-mathematiques-2.png";
import _jeux_mathematiques from "./thumbnails/jeux-mathematiques.png";
import _la_bonne_image from "./thumbnails/la-bonne-image.png";
import _labyrinthes_pdf from "./thumbnails/labyrinthes-pdf.png";
import _labyrinthes from "./thumbnails/labyrinthes.png";
import _le_bon_mot from "./thumbnails/le-bon-mot.png";
import _lecture_flash from "./thumbnails/lecture-flash.png";
import _lecture_heure_generateur from "./thumbnails/lecture-heure-generateur.png";
import _lecture_heure from "./thumbnails/lecture-heure.png";
import _little_challenge_book from "./thumbnails/little-challenge-book.png";
import _logitic from "./thumbnails/logitic.png";
import _logixourriel from "./thumbnails/logixourriel.png";
import _loto from "./thumbnails/loto.png";
import _mastermind from "./thumbnails/mastermind.png";
import _mdp_gen from "./thumbnails/mdp-gen.png";
import _media_folder_generator from "./thumbnails/media-folder-generator.png";
import _meme_indice from "./thumbnails/meme-indice.png";
import _memory_generateur from "./thumbnails/memory-generateur.png";
import _memory from "./thumbnails/memory.png";
import _messagerie from "./thumbnails/messagerie.png";
import _mesures from "./thumbnails/mesures.png";
import _minuterie from "./thumbnails/minuterie.png";
import _modelisation_rdp from "./thumbnails/modelisation-rdp.png";
import _montessori from "./thumbnails/montessori.png";
import _mosaique from "./thumbnails/mosaique.png";
import _multiplication from "./thumbnails/multiplication.png";
import _mur_sonore from "./thumbnails/mur-sonore.png";
import _nombre_pense from "./thumbnails/nombre-pense.png";
import _nombres from "./thumbnails/nombres.png";
import _np2016 from "./thumbnails/np2016.png";
import _numeration from "./thumbnails/numeration.png";
import _numop from "./thumbnails/numop.png";
import _operations from "./thumbnails/operations.png";
import _ordonner from "./thumbnails/ordonner.png";
import _ordonombres from "./thumbnails/ordonombres.png";
import _ordre_alpha from "./thumbnails/ordre-alpha.png";
import _orlf from "./thumbnails/orlf.png";
import _ortho_lex from "./thumbnails/ortho-lex.png";
import _orthographe from "./thumbnails/orthographe.png";
import _outilstice from "./thumbnails/outilstice.png";
import _papota2 from "./thumbnails/papota2.png";
import _parachute from "./thumbnails/parachute.png";
import _parapente from "./thumbnails/parapente.png";
import _parcours_de_phrases from "./thumbnails/parcours-de-phrases.png";
import _pareil_pas_pareil from "./thumbnails/pareil-pas-pareil.png";
import _parking from "./thumbnails/parking.png";
import _pense from "./thumbnails/pense.png";
import _phonologie from "./thumbnails/phonologie.png";
import _phrases from "./thumbnails/phrases.png";
import _picbille from "./thumbnails/picbille.png";
import _picturetopuzzle from "./thumbnails/picturetopuzzle.png";
import _polyedres from "./thumbnails/polyedres.png";
import _pourquoiparceque from "./thumbnails/pourquoiparceque.png";
import _puissance10 from "./thumbnails/puissance10.png";
import _qrcode from "./thumbnails/qrcode.png";
import _quadrillage from "./thumbnails/quadrillage.png";
import _rallye_lecture from "./thumbnails/rallye-lecture.png";
import _rcn2017 from "./thumbnails/rcn2017.png";
import _recadrer from "./thumbnails/recadrer.png";
import _reperage from "./thumbnails/reperage.png";
import _reussites from "./thumbnails/reussites.png";
import _robots from "./thumbnails/robots.png";
import _rounded_word_cards from "./thumbnails/rounded-word-cards.png";
import _rounded_word from "./thumbnails/rounded-word.png";
import _rsq from "./thumbnails/rsq.png";
import _rsqgenerateur from "./thumbnails/rsqgenerateur.png";
import _ruche_mots from "./thumbnails/ruche-mots.png";
import _ruche_nombres from "./thumbnails/ruche-nombres.png";
import _sciences from "./thumbnails/sciences.png";
import _scrabble from "./thumbnails/scrabble.png";
import _sept_familles from "./thumbnails/sept-familles.png";
import _sig from "./thumbnails/sig.png";
import _simulation_salaire from "./thumbnails/simulation-salaire.png";
import _souris from "./thumbnails/souris.png";
import _soixante from "./thumbnails/soixante.png";
import _ssq from "./thumbnails/ssq.png";
import _ssqgenerateur from "./thumbnails/ssqgenerateur.png";
import _sudoku from "./thumbnails/sudoku.png";
import _superpositions from "./thumbnails/superpositions.png";
import _symbole_commun from "./thumbnails/symbole-commun.png";
import _symetrie from "./thumbnails/symetrie.png";
import _tableau_bavard from "./thumbnails/tableau-bavard.png";
import _tables_addition from "./thumbnails/tables-addition.png";
import _tables_division from "./thumbnails/tables-division.png";
import _tables_multiplication from "./thumbnails/tables-multiplication.png";
import _tables_soustraction from "./thumbnails/tables-soustraction.png";
import _tempsquireste from "./thumbnails/tempsquireste.png";
import _thermometre from "./thumbnails/thermometre.png";
import _tutos from "./thumbnails/tutos.png";
import _vehicules from "./thumbnails/vehicules.png";
import _video_tag from "./thumbnails/video-tag.png";
import _vocatego from "./thumbnails/vocatego.png";
import _wpprezi from "./thumbnails/wpprezi.png";
import _youtube from "./thumbnails/youtube.png";
import _ytsearch from "./thumbnails/ytsearch.png";
export const _4PIONS = _4pions;
export const _PORTRAITCHINOIS = _PortraitChinois;
export const _PROBLEMES = _Problemes;
export const _PRODECRITS = _ProdEcrits;
export const _ABAQUE = _abaque;
export const _ABAQUES_TAQUINS = _abaques_taquins;
export const _ACROSTICHE = _acrostiche;
export const _ADDIGRILLE = _addigrille;
export const _ADDITIONS = _additions;
export const _AGAMOGRAPHE = _agamographe;
export const _ALEA_CARTES = _alea_cartes;
export const _ALPHABET = _alphabet;
export const _ANAGRAMMES = _anagrammes;
export const _ANGLES = _angles;
export const _ANIMATIONS = _animations;
export const _ARBORESCENCE = _arborescence;
export const _ARDOISE = _ardoise;
export const _AUDIO_TAG = _audio_tag;
export const _AWARHOL = _awarhol;
export const _BARRES_DOIGTS = _barres_doigts;
export const _BATAILLE = _bataille;
export const _BLOG = _blog;
export const _BOULIER = _boulier;
export const _BOULIERHTML5 = _boulierHTML5;
export const _CALCULMENTAL = _calculmental;
export const _CAMBRIDGE = _cambridge;
export const _CARTAPOINTS = _cartapoints;
export const _CASES4BB = _cases4bb;
export const _CHATEAU = _chateau;
export const _CHEMINS_DE_PHRASES = _chemins_de_phrases;
export const _CHOIXPEAU = _choixpeau;
export const _CIBLES = _cibles;
export const _CLASSEMENT = _classement;
export const _CLAVIER = _clavier;
export const _COLOGRILLES = _cologrilles;
export const _COLOGRILLESGENERATEUR = _cologrillesgenerateur;
export const _COMPLEMENTS = _complements;
export const _COMPTER = _compter;
export const _COMPTEUR = _compteur;
export const _CONCATENATOR = _concatenator;
export const _CONJUGAISON = _conjugaison;
export const _CONVERSION = _conversion;
export const _CONVERSION2 = _conversion2;
export const _CONVERSIONS = _conversions;
export const _COURSEAU21 = _courseau21;
export const _DECIMAUX = _decimaux;
export const _DENOMBREMENT = _denombrement;
export const _DICTEE = _dictee;
export const _DICTEENOMBRES = _dicteenombres;
export const _DIDAPAGES = _didapages;
export const _DIFFERENCES = _differences;
export const _DISCRIMINATION = _discrimination;
export const _DIVISATOR = _divisator;
export const _DOMINOS = _dominos;
export const _EACROSTICHES = _eacrostiches;
export const _ECOUTE_ET_RANGE = _ecoute_et_range;
export const _ECRANS_IPAD = _ecrans_ipad;
export const _EDIDAFUSION = _edidafusion;
export const _EMBOUTEILLAGES = _embouteillages;
export const _ESTIMER_FRACTION = _estimer_fraction;
export const _ESTIMHEURE = _estimheure;
export const _ETIQUETTES_VIRTUELLES = _etiquettes_virtuelles;
export const _ETIQUETTES = _etiquettes;
export const _FABRICAMO = _fabricamo;
export const _FABRIQUEHISTOIRES = _fabriquehistoires;
export const _FLIPFLAP = _flipflap;
export const _FLUENCE = _fluence;
export const _FRACTIONS_GENERATEUR = _fractions_generateur;
export const _FRACTIONS = _fractions;
export const _FRISE_CIRCULAIRE = _frise_circulaire;
export const _FRISE = _frise;
export const _FUSIONLIGNES = _fusionLignes;
export const _FUSIONDDP = _fusionddp;
export const _GABUZOMEU = _gabuzomeu;
export const _GRADUATIONS = _graduations;
export const _GRID4BOT = _grid4bot;
export const _GROUPEMENTS = _groupements;
export const _HASHTAGFROM = _hashtagfrom;
export const _HOMOPHONES = _homophones;
export const _IMAGES_SEQUENTIELLES = _images_sequentielles;
export const _IMAGESETSONS = _imagesetsons;
export const _JEUX_LECTURE = _jeux_lecture;
export const _JEUX_MATHEMATIQUES_1 = _jeux_mathematiques_1;
export const _JEUX_MATHEMATIQUES_2 = _jeux_mathematiques_2;
export const _JEUX_MATHEMATIQUES = _jeux_mathematiques;
export const _LA_BONNE_IMAGE = _la_bonne_image;
export const _LABYRINTHES_PDF = _labyrinthes_pdf;
export const _LABYRINTHES = _labyrinthes;
export const _LE_BON_MOT = _le_bon_mot;
export const _LECTURE_FLASH = _lecture_flash;
export const _LECTURE_HEURE_GENERATEUR = _lecture_heure_generateur;
export const _LECTURE_HEURE = _lecture_heure;
export const _LITTLE_CHALLENGE_BOOK = _little_challenge_book;
export const _LOGITIC = _logitic;
export const _LOGIXOURRIEL = _logixourriel;
export const _LOTO = _loto;
export const _MASTERMIND = _mastermind;
export const _MDP_GEN = _mdp_gen;
export const _MEDIA_FOLDER_GENERATOR = _media_folder_generator;
export const _MEME_INDICE = _meme_indice;
export const _MEMORY_GENERATEUR = _memory_generateur;
export const _MEMORY = _memory;
export const _MESSAGERIE = _messagerie;
export const _MESURES = _mesures;
export const _MINUTERIE = _minuterie;
export const _MONTESSORI = _montessori;
export const _MODELISATION_RDP = _modelisation_rdp;
export const _MOSAIQUE = _mosaique;
export const _MULTIPLICATION = _multiplication;
export const _MUR_SONORE = _mur_sonore;
export const _NOMBRE_PENSE = _nombre_pense;
export const _NOMBRES = _nombres;
export const _NP2016 = _np2016;
export const _NUMERATION = _numeration;
export const _NUMOP = _numop;
export const _OPERATIONS = _operations;
export const _ORDONNER = _ordonner;
export const _ORDONOMBRES = _ordonombres;
export const _ORDRE_ALPHA = _ordre_alpha;
export const _ORLF = _orlf;
export const _ORTHO_LEX = _ortho_lex;
export const _ORTHOGRAPHE = _orthographe;
export const _OUTILSTICE = _outilstice;
export const _PAPOTA2 = _papota2;
export const _PARACHUTE = _parachute;
export const _PARAPENTE = _parapente;
export const _PARCOURS_DE_PHRASES = _parcours_de_phrases;
export const _PAREIL_PAS_PAREIL = _pareil_pas_pareil;
export const _PARKING = _parking;
export const _PENSE = _pense;
export const _PHONOLOGIE = _phonologie;
export const _PHRASES = _phrases;
export const _PICBILLE = _picbille;
export const _PICTURETOPUZZLE = _picturetopuzzle;
export const _POLYEDRES = _polyedres;
export const _POURQUOIPARCEQUE = _pourquoiparceque;
export const _PUISSANCE10 = _puissance10;
export const _QRCODE = _qrcode;
export const _QUADRILLAGE = _quadrillage;
export const _RALLYE_LECTURE = _rallye_lecture;
export const _RCN2017 = _rcn2017;
export const _RECADRER = _recadrer;
export const _REPERAGE = _reperage;
export const _REUSSITES = _reussites;
export const _ROBOTS = _robots;
export const _ROUNDED_WORD_CARDS = _rounded_word_cards;
export const _ROUNDED_WORD = _rounded_word;
export const _RSQ = _rsq;
export const _RSQGENERATEUR = _rsqgenerateur;
export const _RUCHE_MOTS = _ruche_mots;
export const _RUCHE_NOMBRES = _ruche_nombres;
export const _SCIENCES = _sciences;
export const _SCRABBLE = _scrabble;
export const _SEPT_FAMILLES = _sept_familles;
export const _SIG = _sig;
export const _SIMULATION_SALAIRE = _simulation_salaire;
export const _SOURIS = _souris;
export const _SOIXANTE = _soixante;
export const _SSQ = _ssq;
export const _SSQGENERATEUR = _ssqgenerateur;
export const _SUDOKU = _sudoku;
export const _SUPERPOSITIONS = _superpositions;
export const _SYMBOLE_COMMUN = _symbole_commun;
export const _SYMETRIE = _symetrie;
export const _TABLEAU_BAVARD = _tableau_bavard;
export const _TABLES_ADDITION = _tables_addition;
export const _TABLES_DIVISION = _tables_division;
export const _TABLES_MULTIPLICATION = _tables_multiplication;
export const _TABLES_SOUSTRACTION = _tables_soustraction;
export const _TEMPSQUIRESTE = _tempsquireste;
export const _THERMOMETRE = _thermometre;
export const _TUTOS = _tutos;
export const _VEHICULES = _vehicules;
export const _VIDEO_TAG = _video_tag;
export const _VOCATEGO = _vocatego;
export const _WPPREZI = _wpprezi;
export const _YOUTUBE = _youtube;
export const _YTSEARCH = _ytsearch;
