import React from "react";
export default ({ path = "https://micetf.fr" }) => {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = `${path}/outils`;
    const handleClick = (e) => {
        e.preventDefault();
        a.click();
    };

    return (
        <button
            className="btn btn-secondary my-1 mx-1"
            title="Chercher un outil sur micetf.fr..."
            onClick={handleClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                height="1em"
                fill="#f8f9fa"
            >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
            </svg>
        </button>
    );
};
